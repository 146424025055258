import { connect } from "react-redux";
import React from "react";
import { ScreenClassRender, Container } from "react-grid-system";
import { Menu, Resources } from "../Components";
import { Functions } from "../Helpers";
import { Store } from "../Modules";

class PolicyPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      darkMode: Functions.lightOrDark(),
    };
  }

  content = (type, content) => {
    if (type === "h3") {
      return <h3>{content}</h3>;
    }
    if (type === "h4") {
      return <h4>{content}</h4>;
    }
    if (type === "p") {
      return <p dangerouslySetInnerHTML={{ __html: content }} />;
    }

    return <div>{content}</div>;
  };

  render() {
    return (
      <div className={this.state.darkMode ? "app app--dark" : "app"}>
        <ScreenClassRender
          render={(screen) => (
            <Menu
              change={(value) =>
                Store.dispatch({
                  type: "CORE",
                  key: "language",
                  value,
                })
              }
              language={this.props.redux.language}
              mobile={["xs", "sm"].includes(screen)}
              open={this.state.menuOpen}
              onOpen={() => this.setState({ menuOpen: true })}
              onClose={() => this.setState({ menuOpen: false })}
              partners={this.props.redux.partners}
            />
          )}
        />
        <div className="container">
          <Container>
            {Resources.get(
              "PrivacyPolicyContent",
              this.props.redux.language
            ).map((block) =>
              this.content(
                block.type,
                block.content.replace(
                  ":link",
                  '<a href="https://policies.google.com/technologies/ads" target="_blank">Google reklam ve içerik ağı gizlilik politikasını</a>'
                )
              )
            )}
          </Container>
        </div>
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(PolicyPage);
