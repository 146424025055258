import { connect } from "react-redux";
import React, { Component } from "react";
import { ScreenClassRender } from "react-grid-system";
import { Menu } from "../Components";
import { BlogContainer } from "../Containers";
import { Functions } from "../Helpers";
import { Store } from "../Modules";

class BlogPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      blogs: [],
      darkMode: Functions.lightOrDark(),
    };
  }

  render() {
    return (
      <div className={this.state.darkMode ? "app app--dark" : "app"}>
        <ScreenClassRender
          render={(screen) => (
            <Menu
              change={(value) =>
                Store.dispatch({
                  type: "CORE",
                  key: "language",
                  value,
                })
              }
              language={this.props.redux.language}
              mobile={["xs", "sm"].includes(screen)}
              open={this.state.menuOpen}
              onOpen={() => this.setState({ menuOpen: true })}
              onClose={() => this.setState({ menuOpen: false })}
              partners={this.props.redux.partners}
            />
          )}
        />
        <BlogContainer />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(BlogPage);
