const Resource = {
  Okay: "Tamam",
  Testing: "Ölçülüyor",
  Repeat: "Tekrarla",
  CookieDescription:
    "Bu web sitesi kullanıcı deneyimini arttırmak için çerezler kullanmaktadır.",
  PrivacyPolicy: "Gizlilik Politikası",
  Contact: "İletişim",
  Network: "Ağ",
  Ping: "Ping",
  Download: "İndirme",
  Upload: "Yükleme",
  Server: "Sunucu",
  Client: "Sağlayıcı",
  WebsiteDescription:
    "internethizi.com ile internet sağlayıcınızın indirme hızını, yükleme hızını çok kısa bir sürede test edebilirsiniz. İnternet hızını ölçmeyi / internet hızı testini en iyisi ile, internethizi.com ile yapın. İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest",
  WebsiteTitle:
    "İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest",
  Website: "https://internethizi.com",
  Logo: "https://internethizi.com/i/logo.png",
  Analytics: "UA-168287737-2",
  Email: "mailto:reklam@internethizi.com",
  PrivacyPolicyContent: [
    {
      type: "h3",
      content: "Gizlilik Politikası",
    },
    {
      type: "p",
      content:
        'internethizi.com sitesini ziyaret etmekle ve bu site vasıtasıyla sunduğumuz hizmetlerden yararlanmayı talep etmekle, işbu "Gizlilik Politikası"nda belirtilen şartları kabul etmiş sayılmaktasınız.Internethizi.com için ziyaretçilerinin gizliliği çok önemlidir. Sitemizde geçerli olan genel gizlilik politikası ve standardımız bu sayfada yer almaktadır. Internethizi.com uygun gördüğü taktirde gizlilik politikasını değiştirebilir, bu nedenle her ziyaretinizde bu sayfayı okumanızı öneririz.',
    },
    {
      type: "h4",
      content: "Bilgilerin Kullanılması ve Korunması",
    },
    {
      type: "p",
      content:
        "Sitemizi ziyaret eden ve / veya internet hızı testi yapanlarla ilgili olarak elde ettiğimiz bilgiler, gösterdiğimiz en yüksek özen derecesinde gizli olarak korunmaktadır. Bu bilgilere talep ettiği hizmeti yerine getirmek amacıyla ve hizmetin gerektirdiği ölçüde, internethizi.com personeli tarafından ulaşılabilmektedir ve üçüncü partilerle kesinlikle paylaşılmamaktadır. Bilgisayar korsanlığı vs yoluyla koruma sisteminin etkisiz bırakılması halinde doğacak zararlardan dolayı internethizi.com sorumluluk kabul etmemektedir.",
    },
    {
      type: "h4",
      content: "Bilgi Kaynakları",
    },
    {
      type: "p",
      content:
        'Sizin, internethizi.com sitesini kullanarak veya e-mail gibi diğer yollarla bize sağladığınız bilgiler tarafımızdan alınmakta ve kaydedilmektedir. internethizi.com ziyaretiniz sırasında, bilgisayarlarımızın sizi otomatik olarak tanımasına yarayan ve "cookies" olarak adlandırılan tanımlama sistemi yoluyla, daha önce sitemize yaptığınız ziyaretler, aldığınız hizmetler gibi hususlarda elde edilen bilgilerdir. Bilgisayarınızın "yardım" menüsünde, bu özelliği kısıtlamak veya tamamen etkisiz hale getirmek için ne yapmanız gerektiğini bulabilirsiniz. Sitemizde Google Analytics kullanılmaktadır. Google üçüncü taraf satıcı olarak, sitemizde reklam yayınlamak için çerezlerden faydalanmaktadır. Google, DART çerezlerini faydalanarak ziyaretçilerimize, sitemize ve internet\'teki diğer sitelere yapılan ziyaretlere ilişkin reklamlar gösterir. Ziyaretçilerimiz, DART çerezinin kullanılmasını engellemek isterlerse <a href="https://policies.google.com/technologies/ads" target="_blank">Google reklam ve içerik ağı gizlilik politikasını</a> ziyaret ederek bilgi alabilirler.',
    },
  ],
  Questions: [
    {
      title: "speedot.net nedir?",
      description:
        "speedot.net internet hızını ölçen güvenilir ve ücretsiz bir web sitesidir.",
    },
    {
      title: "speedot.net ne ölçüyor?",
      description:
        "speedot.net kullandığınız cihazdaki indirme hızınızı ve yükleme hızınızı ölçmektedir.",
    },
  ],
  faqText: "Sık Sorulan Sorular",
};

export default Resource;
