const Resource = {
  Okay: "حسنا",
  Testing: "اختبارات",
  Repeat: "كرر",
  CookieDescription:
    "يستخدم هذا الموقع ملفات تعريف الارتباط لتحسين تجربة المستخدم.",
  PrivacyPolicy: "سياسة خاصة",
  Contact: "اتصل",
  Network: "شبكة الاتصال",
  Ping: "بينغ",
  Download: "تحميل",
  Upload: "رفع",
  Server: "الخادم",
  Client: "زبون",
  WebsiteTitle:
    "اختبار سرعة الإنترنت - فحص سرعة الإنترنت - اختبار السرعة - اختبار سرعة DSL",
  faqText: "FAQ",
  PrivacyPolicyContent: [
    {
      type: "h3",
      content: "سياسة الخصوصية:",
    },
    {
      type: "p",
      content:
        'عند زيارتك لموقع speedot.net وطلبك لاستخدام الخدمات التي نقدِّمها عبر هذا الموقع، فإنك تُعَدُّ موافقاً على الشروط المحدَّدة في "سياسة الخصوصية" هذه. خصوصية الزائرين مهمة جداً بالنسبة لـspeedot.net. سياستنا الخصوصية العامة والقابلة للتطبيق بشكل قياسي على موقعنا الإلكتروني موجودة على هذه الصفحة. قد تقوم speedot.net بتعديل سياسة الخصوصية إذا وجدت ذلك مناسباً، ولذلك نقترح عليك قراءة هذه الصفحة عند كل زيارة.',
    },
    {
      type: "h4",
      content: "استخدام المعلومات وحمايتها:",
    },
    {
      type: "p",
      content:
        "المعلومات التي حصلنا عليها والتي تتعلق بالأشخاص الذين يقومون بزيارة موقعنا الإلكتروني و/أو اختبار سرعة الإنترنت لديهم محمية بشكل موثوق وبأعلى درجات الاهتمام. يمكن لكادر speedot.net أن يصلوا إلى هذه المعلومات من أجل تلبية الخدمة المطلوبة أو إلى الحد الذي تتطلبه هذه الخدمة، ولا تتم مشاركتها مع أي طرف ثالث. لا تقبل speedot.net أي مسؤولية عن الخسارات التي قد تنشأ عند تعطيل نظام الحماية بسبب القرصنة والأمور المشابهة.",
    },
    {
      type: "h4",
      content: "مصادر المعلومات:",
    },
    {
      type: "p",
      content:
        'نقوم بتلقي المعلومات التي تقدِّمها لنا عند استخدام speedot.net أو بطرق أخرى مثل البريد الإلكتروني، وبتسجيلها. يتم الحصول على هذه المعلومات بما يتعلق بزياراتك السابقة، والخدمات التي تلقيتها مسبقاً وغير ذلك من خلال نظام تعرف على الهوية يعرَف باسم "ملفات تعريف الارتباط" تمكِّن كمبيوتراتنا من التعرف عليك بشكل آلي. في قائمة "المساعدة" في جهاز الكمبيوتر الخاص بك، يمكنك التعرف على ما يجب عليك القيام به من أجل وضع خدود لهذه الخاصية أو تعطيلها بشكل كامل. يقوم موقعنا الإلكتروني باستخدام تحليل غوغل (غوغل أناليتيكس). تقوم شركة غوغل باستخدام ملفات تعريف الارتباط كبائع طرف ثالث من أجل الإعلان على موقعنا الإلكتروني. تظهر غوغل إعلانات تتعلق بزوارنا، وموقعنا الإلكتروني وزيارات المواقع الإلكترونية الأخرى باستخدام ملفات تعريف الارتباط DART. يمكن لزوارنا زيارة صفحة إعلانات غوغل وسياسة خصوصية شبكة المحتوى من أجل الحصول على معلومات إضافية إذا كانوا يرغبون بتعطيل استخدام ملفات تعريف ارتباط DART.',
    },
  ],
  Questions: [
    {
      title: "ما هي الشبكة؟",
      description:
        "الشبكة هي مجموعة تتكون من جهازَي كمبيوتر أو أكثر أو أجهزة إلكترونية أخرى متصلة فيما بينها من أجل نقل البيانات ومشاركة المصادر.",
    },
    {
      title: "ما هو البينغ؟",
      description:
        "البينغ هي إشارة يتم إرسالها من المخدِّم من أجل اختبار المدة التي تستغرقها الاستجابة.",
    },
  ],
};

export default Resource;
