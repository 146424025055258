import React from "react";
import { Link } from "react-router-dom";
import { Settings } from "../Helpers";

const Logo = () => (
  <Link to={"/"}>
    <img
      className="logo"
      src={Settings.multilingual ? "/i/logo.svg" : "/i/logo.svg"}
      alt="İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
    />
  </Link>
);

export default Logo;
