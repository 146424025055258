import React from "react";
import { Link } from "react-router-dom";
import Title from "./Title";
import Paragraph from "./Paragraph";

const Component = ({ src, content, title, id }) => (
  <Link to={"/blog/" + id}>
    <img
      alt={title}
      src={src}
      style={{
        width: "100%",
        height: "auto",
        borderRadius: 4,
        boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.04)",
        marginBottom: 24,
      }}
    />
    <Title bottom={12}>{title}</Title>
    <Paragraph bottom={24}>{content}</Paragraph>
  </Link>
);

export default Component;
