const Resource = {
  Okay: "Okay",
  Testing: "Testen",
  Repeat: "Wiederholen",
  CookieDescription:
    "Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.",
  PrivacyPolicy: "Datenschutz",
  Contact: "Kontakt",
  Network: "Netzwerk",
  Ping: "Ping",
  Download: "Download",
  Upload: "Upload",
  Server: "Server",
  Client: "Klient",
  WebsiteTitle:
    "Internet-Geschwindigkeitstest - Internet-Geschwindigkeitstest - SpeedTest - DSL-Geschwindigkeitstest",
  faqText: "FAQ",
};

export default Resource;
