const Resource = {
  Okay: "はい",
  Testing: "テスト",
  Repeat: "繰り返す",
  CookieDescription:
    "このウェブサイトは、ユーザーエクスペリエンスを向上させるためにCookieを使用しています。",
  PrivacyPolicy: "個人情報保護方針",
  Contact: "連絡先",
  Network: "通信網",
  Ping: "Ping",
  Download: "ダウンロード",
  Upload: "アップロード",
  Server: "サーバ",
  Client: "クライアント",
  WebsiteTitle:
    "インターネット速度テスト-インターネット速度チェック-SpeedTest-DSL速度テスト",
  faqText: "FAQ",
  PrivacyPolicyContent: [
    {
      type: "h3",
      content: "個人情報保護方針",
    },
    {
      type: "p",
      content:
        "当社ウェブサイトのspeedot.netにアクセスし、本ウェブサイトが提供するサービスの利用を申請した場合、「個人情報保護方針」に記載された条件に同意したものとみなされます。当社にとって、お客様のプライバシーを保護することは非常に重要です。このページには、当社のウェブサイトで適用される一般的な個人情報保護方針と基準が記載されています。当社が適切と判断した場合には、個人情報保護方針の内容を変更することがありますので、ご利用の都度、このページを確認いただくことをお勧めいたします。",
    },
    {
      type: "h4",
      content: "情報の利用と保護について",
    },
    {
      type: "p",
      content:
        "当社ウェブサイトを訪問した人やインターネット速度をテストした人に関連して得た情報は、厳重に機密保護されています。弊社スタッフは、お客様にサービスを提供するため、またはサービス提供に必要な範囲でこの情報を利用しますが、第三者に共有されることはありません。当社は、ハッキングなどにより保護システムが無効化された場合に発生する損害について、一切の責任を負いません。",
    },
    {
      type: "h4",
      content: "情報源",
    },
    {
      type: "p",
      content:
        "当社のサービスを利用、または電子メールなどのその他の方法でお客様から提供された情報を受け取り、記録し保存します。前回のアクセスでお客様が受けたサービスから、「cookies」と呼ばれるシステムで当社コンピュータが自動的にお客様を識別することができます。「ヘルプ」メニューでは、この機能を制限する方法や、完全に無効にする方法が確認できます。当社のウェブサイトでは、Google アナリティクスを使用しています。Googleは第三者の販売者としてcookiesを使用し、当社のウェブサイトに広告を掲載しています。 Googleは、DART cookiesを使用して、当社ウェブサイトの訪問者および他のウェブサイトに関連する広告を表示します。DART cookiesを無効にしたい場合は、Google広告およびコンテンツネットワークのプライバシーポリシーを参照してください。",
    },
  ],
  Questions: [
    {
      title: "ネットワークとは？",
      description:
        "データの転送やソース共有のために、相互に接続された2台以上のコンピュータやその他のディバイス、電子機器からなるグループのことです。",
    },
    {
      title: "Pingとは？",
      description:
        "サーバーに信号を送り、応答までにかかる時間をテストするものです。",
    },
  ],
};

export default Resource;
