import React from "react";
import Resources from "./Resources";

const Button = ({
  loading,
  onClick,
  language = "english",
  testing = Resources.get("Testing", language),
  repeat = Resources.get("Repeat", language),
}) =>
  loading ? (
    <button
      itemScope
      itemType="https://schema.org/ControlAction"
      actionStatus="ActiveActionStatus"
      href="#"
      className="button"
      onClick={loading ? undefined : onClick}
    >
      {testing}
      <img
        src="i/icon-try-again.svg"
        className="button__icon button__icon--animation"
        alt={
          "Tekrar Dene - İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
        }
      />
    </button>
  ) : (
    <button
      itemscope
      itemtype="https://schema.org/ControlAction"
      actionStatus="CompletedActionStatus"
      href="#"
      className="button"
      onClick={loading ? undefined : onClick}
    >
      {repeat}
      <img
        src="i/icon-try-again.svg"
        className="button__icon"
        alt={
          "Tekrar Dene - İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
        }
      />
    </button>
  );

export default Button;
