import React from "react";
import ReactLoading from "react-loading";
import { ScreenClassRender } from "react-grid-system";
import ThemeSwitcher from "./ThemeSwitcher";

const Speed = ({ loading = true, value, kbps, dark, onClick }) => (
  <div className="speed center">
    <ScreenClassRender
      render={(screen) =>
        !["xs", "sm"].includes(screen) ? null : (
          <ThemeSwitcher menu dark={dark} onClick={onClick} />
        )
      }
    />
    <canvas id="canvas" width="300" height="300"></canvas>
    {loading ? (
      <ReactLoading
        type={"spin"}
        color={"rgba(0,0,0,0.37)"}
        height={36}
        width={36}
      />
    ) : (
      <div className="large-detail">
        <div className="large-detail__icon">
          <img
            src="i/icon-download.svg"
            alt="İnternet Hız Testi - İnternet Hızı Ölçme - Hız Testi - SpeedTest"
          />
        </div>
        <div className="large-detail__titles">
          <span className="large-detail__title">{value}</span>
          <span className="large-detail__subtitle">
            {kbps ? "Kbps" : "Mbps"}
          </span>
        </div>
      </div>
    )}
  </div>
);

export default Speed;
