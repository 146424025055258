import React from "react";
import Text from "./Text";
import { Colors } from "../Helpers";

const Component = ({ children, bottom }) => (
  <Text
    size={18}
    line={32}
    weight="400"
    color={Colors.independence}
    block
    bottom={bottom}
  >
    {children}
  </Text>
);

export default Component;
